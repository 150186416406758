export default {
    namespaced: true,
    state: {
        onePathIndex: Number,
    },
    mutations: {
        onePathIndex(state, n) {
            state.onePathIndex = n;
        }
    },
    actions: {
        setOnePathIndex(context, data) {
            context.commit('onePathIndex', data);
        }
    },
    getters: {
        getOnePathIndex: (state) => {
            return state.onePathIndex;
        }
    },
};
