import { defineComponent, ref, reactive } from "vue";
import { useRoute, useRouter } from "vue-router";
const __default__ = defineComponent({
    setup() {
        let NavList = ref([
            {
                name: "首页",
                id: 1,
            },
            {
                name: "功能介绍",
                id: 2,
            },
            {
                name: "关于我们",
                id: 3,
            },
        ]);
        let tabs = reactive({
            actives: sessionStorage.getItem('index') ? sessionStorage.getItem('index') : 0,
        });
        let color = "red";
        const route = useRoute();
        const router = useRouter();
        // console.log('route',route,'router',router);
        // 改变标签
        const changeTab = (i) => {
            sessionStorage.setItem('index', i + 1);
            tabs.actives = i + 1;
            console.log(i, tabs.actives);
            switch (i) {
                case 2:
                    // 跳转关于我们
                    setTimeout(() => {
                        router.push("/about");
                    }, 500);
                    return;
                case 1:
                    // 功能介绍
                    setTimeout(() => {
                        router.push("/func");
                    }, 500);
                    return;
                case 0:
                    // 首页
                    setTimeout(() => {
                        router.push("/");
                    }, 500);
                    return;
                default:
            }
        };
        return {
            NavList,
            changeTab,
            tabs,
            color,
        };
    },
});
import { useCssVars as _useCssVars } from 'vue';
const __injectCSSVars__ = () => {
    _useCssVars(_ctx => ({
        "33627ebc": (_ctx.color)
    }));
};
const __setup__ = __default__.setup;
__default__.setup = __setup__
    ? (props, ctx) => { __injectCSSVars__(); return __setup__(props, ctx); }
    : __injectCSSVars__;
export default __default__;
