import './plugins/axios';
import { createApp } from 'vue';
import App from './App.vue';
// 路由
import router from './router';
// 状态管理
import store from './store';
// import './lib/jquery-1.10.2.js'
// import './lib/jquery.min.js'
import installElementPlus from './plugins/element';
// vue3版本的全局函数
// App.config.globalProperties.$axios = HttpService;
// App.config.globalProperties.$router = router;
const app = createApp(App);
installElementPlus(app);
app.use(store).use(router).mount('#app');
