import UserModule from './modules/user';
import { createStore } from 'vuex';
// export default new Vuex.Store({
export default createStore({
    state: {
        lang: String,
    },
    mutations: {
        lang(state, n) {
            state.lang = n;
        }
    },
    actions: {
        setLang(content, data) {
            content.commit('lang', data);
        }
    },
    getters: {
        getLang: state => {
            return state.lang;
        }
    },
    // 模块配置
    modules: {
        UserModule: UserModule
    },
});
