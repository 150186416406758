import { __decorate } from "tslib";
import { Options, Vue } from "vue-class-component";
import { ref } from "vue";
import navlist from "@/components/navlist.vue";
import contentItem from "@/components/contentItem.vue";
import aboutItem from '@/components/aboutItem.vue';
let Home = class Home extends Vue {
    setup() {
        console.log('首页。。。。。。。。。。。。');
        const month = ref("9");
        return {
            month,
        };
    }
};
Home = __decorate([
    Options({
        components: {
            navlist, contentItem, aboutItem
        },
    })
], Home);
export default Home;
